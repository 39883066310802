const locales = [
    {
        label: 'EN',
        lang: 'en',
    },
    // {
    //     label: 'DE',
    //     lang: 'de',
    // },
    {
        label: 'PL',
        lang: 'pl',
    },
];

export default locales;
