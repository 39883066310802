const navigation = [
    {
        name: 'about',
        path: {
            en: '/en/about',
            de: '/de/about',
            pl: '/pl/about',
        },
        label: {
            en: 'About the journal',
            de: 'O czasopiśmie',
            pl: 'O czasopiśmie',
        },
    },
    {
        name: 'general',
        path: {
            en: '/en/general',
            de: '/de/general',
            pl: '/pl/general',
        },
        label: {
            en: 'General information',
            de: 'Uwagi ogólne',
            pl: 'Uwagi ogólne',
        },
    },
    {
        name: 'current',
        path: {
            en: '/en/current',
            de: '/de/current',
            pl: '/pl/current',
        },
        label: {
            en: 'Current issue',
            de: 'Tom bieżący',
            pl: 'Tom bieżący',
        },
    },
    {
        name: 'archive',
        path: {
            en: '/en/archive',
            de: '/de/archive',
            pl: '/pl/archive',
        },
        label: {
            en: 'All volumes',
            de: 'Wszystkie tomy',
            pl: 'Wszystkie tomy',
        },
    },
    {
        name: 'authors',
        path: {
            en: '/en/authors',
            de: '/de/authors',
            pl: '/pl/authors',
        },
        label: {
            en: 'Authors',
            de: 'Autorzy',
            pl: 'Autorzy',
        },
    },
    {
        name: 'keywords',
        path: {
            en: '/en/keywords',
            de: '/de/keywords',
            pl: '/pl/keywords',
        },
        label: {
            en: 'Keywords',
            de: 'Słowa kluczowe',
            pl: 'Słowa kluczowe',
        },
    },
    {
        name: 'editorial-board',
        path: {
            en: '/en/editorial-board',
            de: '/de/editorial-board',
            pl: '/pl/editorial-board',
        },
        label: {
            en: 'Editorial Board',
            de: 'Kolegium Redakcyjne',
            pl: 'Kolegium Redakcyjne',
        },
    },
    {
        name: 'scientific-board',
        path: {
            en: '/en/scientific-board',
            de: '/de/scientific-board',
            pl: '/pl/scientific-board',
        },
        label: {
            en: 'Scientific Board',
            de: 'Rada Naukowa',
            pl: 'Rada Naukowa',
        },
    },
    {
        name: 'evaluation',
        path: {
            en: '/en/evaluation',
            de: '/de/evaluation',
            pl: '/pl/evaluation',
        },
        label: {
            en: 'Indexing',
            de: 'Indeksacja w bazach danych',
            pl: 'Indeksacja w bazach danych',
        },
    },
    {
        name: 'review-process',
        path: {
            en: '/en/review-process',
            de: '/de/review-process',
            pl: '/pl/review-process',
        },
        label: {
            en: 'Review procedure',
            de: 'Procedura recenzowania',
            pl: 'Procedura recenzowania',
        },
    },
    {
        name: 'call-for-papers',
        path: {
            en: '/en/call-for-papers',
            de: '/de/call-for-papers',
            pl: '/pl/call-for-papers',
        },
        label: {
            en: 'Call for papers',
            de: 'Zaproszenie do nadsyłania tekstów',
            pl: 'Zaproszenie do nadsyłania tekstów',
        },
    },
    {
        name: 'authors-info',
        path: {
            en: '/en/authors-info',
            de: '/de/authors-info',
            pl: '/pl/authors-info',
        },
        label: {
            en: 'Information for Authors',
            de: 'Informacje dla Autorów',
            pl: 'Informacje dla Autorów',
        },
    },
    {
        name: 'ethics',
        path: {
            en: '/en/ethics',
            de: '/de/ethics',
            pl: '/pl/ethics',
        },
        label: {
            en: 'Ethical standards',
            de: 'Zasady etyczne',
            pl: 'Zasady etyczne',
        },
    },
    {
        name: 'statement',
        path: {
            en: '/en/statement',
            de: '/de/statement',
            pl: '/pl/statement',
        },
        label: {
            en: 'Declaration',
            de: 'Oświadczenie',
            pl: 'Oświadczenie',
        },
    },
    {
        name: 'special-editions',
        path: {
            en: '/en/special-editions',
            de: '/de/special-editions',
            pl: '/pl/special-editions',
        },
        label: {
            en: 'Special Editions of ORBIS LINGUARUM',
            de: 'Seria „Wydania Specjalne ORBIS LINGUARUM”',
            pl: 'Seria „Wydania Specjalne ORBIS LINGUARUM”',
        },
    },
    {
        name: 'contact',
        path: {
            en: '/en/contact',
            de: '/de/contact',
            pl: '/pl/contact',
        },
        label: {
            en: 'Contact',
            de: 'Kontakt',
            pl: 'Kontakt',
        },
    },
];

export default navigation;
