import React from 'react';
import {Link} from 'gatsby';

import logo from '../images/logo.png';
import open_access from '../images/open_access_black.png';

import dictionary from '../data/_dictionary';
import locales from '../data/_locales';

const Header = ({lang, path}) => (
    <div className="header ornamented">
        <div className="header-left">
            <img  className="logo" src={logo} alt="" />
        </div>
        <div className="header-right">
            <div className="header-right-top">
                <a className="creative-commons-info-link" href={dictionary._linkOpenAccess[lang]} target="_blank" rel="noopener noreferrer">
                    <img  className="creative-commons-info-logo" src={open_access} alt="open access" />
                </a>
                <ul className="header-navigation-list">
                    {locales.map((locale, i) => (
                        <li key={i} className={`header-navigation-list-item ${locale.lang === lang && 'enabled'}`}>
                            <Link to={path && path.replace(`/${lang}/`, `/${locale.lang}/`)}>{locale.label}</Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="header-right-center">
                <div className="title">Orbis Linguarum</div>
            </div>
            <div className="header-right-bottom">
                <div className="info">
                    {`ISSN: 1426-7241 • e-ISSN: 2657-4845 • DOI: 10.23817/olin • ${dictionary.bounceRate[lang]}: 25% (2021)`}
                </div>
            </div>
        </div>
    </div>
);

export default Header;
