const dictionary = {
    _linkOpenAccess: {
        en: 'https://en.wikipedia.org/wiki/Open_access',
        de: 'https://de.wikipedia.org/wiki/Open_Access',
        pl: 'https://pl.wikipedia.org/wiki/Otwarty_dostęp',
    },
    _linkCreativeCommons: {
        en: 'https://creativecommons.org/licenses/by-sa/4.0/deed.en',
        de: 'https://creativecommons.org/licenses/by-sa/4.0/deed.de',
        pl: 'https://creativecommons.org/licenses/by-sa/4.0/deed.pl',
    },
    _linkFB: {
        en: '',
        de: '',
        pl: '',
    },
    _linkInsta: {
        en: '',
        de: '',
        pl: '',
    },
    abstracts: {
        en: 'Abstracts',
        de: 'Abstrakty',
        pl: 'Abstrakty',
    },
    archive: {
        en: 'All volumes',
        de: 'Wszystkie tomy',
        pl: 'Wszystkie tomy',
    },
    audioFile: {
        en: 'Audio file',
        de: 'Tonbeispiel',
        pl: 'Plik dźwiękowy',
    },
    authorsIndex: {
        en: 'Authors',
        de: 'Autorzy',
        pl: 'Autorzy',
    },
    bounceRate: {
        en: 'Rejection rate',
        de: 'Współczynnik odrzuceń',
        pl: 'Współczynnik odrzuceń',
    },
    contents: {
        en: 'Contents',
        de: 'Spis treści',
        pl: 'Spis treści',
    },
    creativeCommonsInfo: {
        en: 'Texts published in the journal “Orbis Linguarum” are available in the Open Access on the basis of the CC BY-SA license.',
        de: 'Teksty publikowane w czasopiśmie „Orbis Linguarum” są udostępniane w wolnym dostępie (Open Access) na podstawie licencji CC BY-SA.',
        pl: 'Teksty publikowane w czasopiśmie „Orbis Linguarum” są udostępniane w wolnym dostępie (Open Access) na podstawie licencji CC BY-SA.',
    },
    issue: {
        en: 'Issue',
        de: 'Tom',
        pl: 'Tom',
    },
    keywords: {
        en: 'Keywords',
        de: 'Słowa kluczowe',
        pl: 'Słowa kluczowe',
    },
    keywordsIndex: {
        en: 'Keyword database',
        de: 'Index der Schlüsselwörter',
        pl: 'Baza słów kluczowych',
    },
    newsletter: {
        en: 'Newsletter',
        de: 'Newsletter',
        pl: 'Newsletter',
    },
    pp: {
        en: 'pp.',
        de: 's.',
        pl: 's.',
    },
    publishedOnline: {
        en: 'published online',
        de: 'data publikacji online',
        pl: 'data publikacji online',
    },
    noResults: {
        en: 'No results',
        de: 'Brak wyników',
        pl: 'Brak wyników',
    },
    searchResultsFor: {
        en: 'Search results for “*”',
        de: 'Wyniki wyszukiwania dla „*”',
        pl: 'Wyniki wyszukiwania dla „*”',
    },
    searchByAuthorResultsFor: {
        en: 'Search results for “*” (author)',
        de: 'Wyniki wyszukiwania dla „*” (autor)',
        pl: 'Wyniki wyszukiwania dla „*” (autor)',
    },
    searchByKeywordResultsFor: {
        en: 'Search results for “*” (keyword)',
        de: 'Wyniki wyszukiwania dla „*” (słowo kluczowe)',
        pl: 'Wyniki wyszukiwania dla „*” (słowo kluczowe)',
    },
};

export default dictionary;
